<template>
  <div>
    <MainFrame>
      <v-card-title>
        <v-icon class="mr-2" color="primary"> mdi-table </v-icon> {{ title }}
      </v-card-title>
      <v-card-subtitle>
        {{ subtitle }}
      </v-card-subtitle>
      <v-card class="my-6 py-4 px-10">
        <v-card-title>
          <v-icon class="mr-2">mdi-magnify</v-icon> Parametros de busqueda
        </v-card-title>
        <v-card-subtitle>
          Seleccione los parametros a utilizar en la busqueda de
          {{ nameObject.toLowerCase() }}
        </v-card-subtitle>
        <v-card-text>
          <v-form ref="searchForm" v-model="validForm" lazy-validation>
            <v-row>
              <v-col cols="12" sm="12" md="4">
                <v-combobox
                  id="input-country"
                  label="País"
                  v-model="searchValues.country"
                  :items="countrys"
                  @keyup.enter="searchData()"
                  :rules="[(v) => !!v || 'El país es requerido']"
                  outlined
                />
              </v-col>
              <v-col cols="12" sm="12" md="4">
                <v-combobox
                  id="input-seller"
                  label="Seller"
                  v-model="searchValues.seller"
                  :items="sellers"
                  @keyup.enter="searchData()"
                  :rules="[(v) => !!v || 'El seller es requerido']"
                  outlined
                />
              </v-col>
              <v-col cols="12" sm="12" md="4">
                <v-combobox
                  id="input-consumer"
                  label="Consumer"
                  v-model="searchValues.consumer"
                  :items="consumers"
                  @keyup.enter="searchData()"
                  :rules="[(v) => !!v || 'El consumer es requerido']"
                  outlined
                />
              </v-col>
            </v-row>
          </v-form>
        </v-card-text>
        <v-card-actions class="px-4">
          <v-spacer />
          <v-btn color="primary" :disabled="!validForm" @click="searchData()">
            Realizar busqueda
          </v-btn>
        </v-card-actions>
      </v-card>
      <GroupTable
        v-if="items.length > 0"
        :headers="headers"
        :items="items"
        :priorityColor="priorityColor"
        :loading.sync="loading"
        @openForm="openForm"
        @showObject="showObject"
        @disableObject="disableObject"
      />
    </MainFrame>
    <v-dialog
      v-model="dialog"
      hide-overlay
      max-width="1000"
      v-if="object"
      persistent
    >
      <div v-if="formPanel">
        <GroupForm
          :headers="headers"
          :object="object"
          :items="items"
          :isEditing="isEditing"
          @UpdateObject="updateObject"
          @SaveObject="saveObject"
          @ClosePanel="formPanel = false"
        />
      </div>
      <div v-if="showPanel">
        <ShowPanel
          :headers="headers"
          :object="object"
          :priorityColor="priorityColor"
          @ClosePanel="showPanel = false"
        />
      </div>
    </v-dialog>
  </div>
</template>

<script>
import utils from "@/shared/utils/Maintainers";
import valuesJson from "./json/values.json";
import { endpoints } from "@/environment/environment";

export default {
  mixins: [utils],
  data: () => ({
    title: "Mantenedor de grupos",
    subtitle: "Mantención y edición de los diferentes grupos de reglas",
    nameObject: "Grupo de reglas",
    loading: false,
  }),
  components: {
    MainFrame: () => import("../../../../layout/MainFrame"),
    GroupTable: () => import("./components/GroupTable"),
    GroupForm: () => import("./components/GroupForm"),
    ShowPanel: () => import("./components/ShowPanel"),
  },
  mounted() {
    this.typeValues = { ...valuesJson };
    this.loadComboBox();
  },
  methods: {
    getAll() {
      this.loading = true;
      this.getGroups();
    },
    getGroups() {
      this.items = [];
      const params = {
        seller: this.searchValues.seller.value,
        consumer: this.searchValues.consumer.value,
        country: this.searchValues.country.value,
      };
      this.$http
        .get(
          `/${endpoints.admin.path}/${endpoints.admin.controllers.groups.path}?sellerId=${params.seller}&countryId=${params.country}&consumerId=${params.consumer}`
        )
        .then((res) => {
          this.items = [...res.body];
          this.items = this.items.sort((a, b) => a.priority - b.priority);
          this.generateHeaders();
          this.getPriorityColors();
          this.loading = false;
        })
        .catch((err) => {
          console.log(err);
          this.loading = false;
        });
    },
    disableObject(v) {
      this.loading = true;
      const onConfirm = () => {
        v.status = !v.status;
        this.updateObject(v);
      };
      const onCancel = () => {
        this.$notification.info("Se ha cancelado la acción");
        this.loading = false;
      };
      this.$alert.warning({
        title: `${v.status ? "Deshabilitar" : "Habilitar"} el grupo de reglas`,
        text: `¿Estas seguro de querer ${
          v.status ? "deshabilitar" : "habilitar"
        } el grupo de reglas '${
          v.ruleGroupName
        }'?, esta acción afectara a todas las reglas asociadas a este grupo.`,
        confirmText: `Si, ${v.status ? "deshabilitar" : "habilitar"}`,
        cancelText: "No, cancelar",
        onConfirm,
        onCancel,
      });
    },
    updateObject(v) {
      this.dialog = false;
      this.formPanel = false;
      this.loading = true;
      v.updateDate = this.dayjs.utc().format();
      this.$http
        .put(
          `/${endpoints.admin.path}/${endpoints.admin.controllers.groups.path}/${v.id}`
        )
        .send(v)
        .then(() => {
          this.$notification.success(
            `Grupo de regla ${v.ruleGroupName} actualizada con exito`
          );
          this.loading = false;
          this.getAll();
        })
        .catch((err) => {
          console.error(err);
          this.$notification.error(`Ha ocurrido un error inesperado`);
          this.loading = false;
        });
    },
    saveObject(v) {
      this.dialog = false;
      this.formPanel = false;
      this.loading = true;
      v = {
        ...v,
        ...{
          countryId: this.searchValues.country.value,
          consumerId: this.searchValues.consumer.value,
          sellerId: this.searchValues.seller.value,
          ruleGroupSystem: false,
          status: true,
          creationDate: this.dayjs.utc().format(),
          user: {
            dni: "161803398",
            username: "ADMIN",
          },
        },
      };
      console.log(v);
      this.$http
        .post(
          `/${endpoints.admin.path}/${endpoints.admin.controllers.groups.path}`
        )
        .send([v])
        .then(() => {
          this.$notification.success(
            `Grupo de regla ${v.ruleGroupName} creada con exito`
          );
          this.loading = false;
          this.getAll();
        })
        .catch((err) => {
          console.error(err);
          this.$notification.error(`Ha ocurrido un error inesperado`);
          this.loading = false;
        });
    },
  },
};
</script>

<style></style>
